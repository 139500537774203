import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Grid,
  Box,
  Typography,
  CardHeader,
  InputBase,
  Divider,
  List,
  ListItem,
  ListItemText,
  withStyles,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import {
  openConfigurationModal,
  setProductConfiguration,
  closeConfigurationModal,
} from "../../redux/selector";
import { connect } from "react-redux";

import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import partsData from "../cart/partsData";
import TempTreeData from "../cart/TempTreeData";

import {
  changeNodeAtPath,
  toggleExpandedForAll,
  getFlatDataFromTree,
} from "../cart/utils/tree-data-utils";

const styles = (theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    width: "95vw",
  },
  closeIconContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  IconButton: {
    color: "white",
  },
  contentContainer: {
    maxHeight: "80vh",
    overflow: "auto",
  },
  list: {
    overflow: "auto",
  },
  CardContent: {
    padding: "2px",
  },
  title: {
    padding: theme.spacing(1),
  },
  treeTitle: {
    color: "black",
  },
  treeBox: {
    display: "flex",
    flexDirection: "row",
    width: "20vw",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    // backgroundColor: "red",
    // backgroundColor: theme.pallete.background.paper,
  },
  select: {
    // color: "",
    width: "100%",
    marginLeft: 10,
    height: "100%",
  },
  totalContainer: {
    marginTop: "1vh",
    width: "60vw",
    // backgroundColor: "red",
    height: "6vh",
  },
});

// const BootstrapInput = withStyles((theme) => ({
//   root: {
//     "label + &": {
//       // marginTop: theme.spacing(3),
//       color: "black",
//     },
//   },
//   input: {
//     borderRadius: 4,
//     position: "relative",
//     padding: "1px",
//     // backgroundColor: theme.palette.background.paper,
//     // border: "1px solid #ced4da",
//     borderColor: theme.palette.primary.secondary,
//     borderWidth: 2,
//     borderStyle: "solid",
//     marginLeft: 10,
//     fontSize: 16,
//     width: "100%",

//     padding: "10px 26px 10px 12px",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     "&:focus": {
//       borderRadius: 4,
//       borderColor: "#80bdff",
//       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
//     },
//   },
// }))(InputBase);

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: "100vh",
//     width: "100vw",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   card: {
//     width: "95vw",
//   },
//   closeIconContainer: {
//     display: "flex",
//     justifyContent: "flex-end",
//   },
//   btnContainer: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   IconButton: {
//     color: "white",
//   },
//   contentContainer: {
//     maxHeight: "80vh",
//     overflow: "auto",
//   },
//   list: {
//     overflow: "auto",
//   },
//   CardContent: {
//     padding: "2px",
//   },
//   title: {
//     padding: theme.spacing(1),
//   },
// }));

class ConfigurationModalWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [{ children: [], options: [], expanded: true }],
      showImageSearch: false,
      choice: "",
      choiceOptions: [],
      path: "",
      compatibleParts: [],
    };
  }

  //   static getDerivedStateFromProps(newProps, oldState) {
  //   console.log('New PROPS : ',newProps);
  //   if (newProps.configurationId) {
  //     console.log('BEFORE FETCH configurationId : ',newProps.configurationId);
  //     console.log('BEFORE FETCH typeof configurationId : ',typeof newProps.configurationId);

  //     const newBom = TempTreeData.find(element => element.id === newProps.configurationId);
  //     console.log('Specification : ',newBom);
  //     return {
  //       treeData: toggleExpandedForAll({ treeData:newBom.partList, expanded : true })
  //     };
  //   }

  //   // Return null if the state hasn't changed
  //   return null;
  // }

  async componentDidMount() {
    const specId = this.props.configurationId;
    if (specId) {
      console.log("BEFORE FETCH configurationId : ", specId);
      console.log("BEFORE FETCH typeof configurationId : ", typeof specId);
      const token = localStorage.getItem("tkn");

      const bomRequest = await fetch(
        `http://172.235.17.44:9874/BOM/SalesBom/display/${specId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let newBom = undefined;
      //const newBom = TempTreeData.find(element => element.id === specId);
      if (bomRequest.status === 200) {
        newBom = await bomRequest.json();
      } else {
        newBom = [];
      }
      console.log("Specification : ", newBom);

      const partRequest = await fetch(
        `http://172.235.17.44:9874/BOM/SalesBom/allparts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let parts = undefined;
      //const parts = TempTreeData.find(element => element.id === specId);
      if (partRequest.status === 200) {
        parts = await partRequest.json();
      } else {
        parts = [];
      }
      console.log("Specification : ", parts);

      this.setState((state) => ({
        compatibleParts: parts,
        treeData: toggleExpandedForAll({
          treeData: newBom.partList,
          expanded: true,
        }),
      }));
    }
  }

  saveConfiguration = () => {
    const data = {
      // choices : getFlatDataFromTree({
      //   treeData: this.state.treeData,
      //   getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
      //   ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
      // }),
      choices: this.state.treeData,
      productId: this.props.productId,
      selectedOptions: this.props.selectedOptions,
    };
    this.props.setProductConfiguration(data);
  };

  saveCloseConfiguration = () => {
    this.saveConfiguration();
    this.props.closeConfigurationModal();
  };

  openConfigurationModal = () => {
    this.props.openConfigurationModal();
  };

  closeConfigurationModal = () => {
    this.props.closeConfigurationModal();
  };

  imageSearchOpen = (choice, options, path) => {
    this.setState({
      showImageSearch: true,
      choice: choice,
      choiceOptions: options,
      path: path,
    });
  };

  imageSearchClose = () => {
    this.setState({ showImageSearch: false });
  };

  render() {
    const { classes } = this.props;

    //const classes = useStyles();
    const getNodeKey = ({ treeIndex }) => treeIndex;

    let totalPrice = 0.0;

    const configPrice = (node) => {
      const childNodes = node.children || [];
      // const currentPrice = node.seperatePrice ? node.salePrice : 0.0;
      const currentPrice = node.salePrice ? node.salePrice : 0.0;
      totalPrice = totalPrice + parseFloat(currentPrice);

      return (
        <Box border="1px solid grey" m={1}>
          <Typography className={classes.title}>
            {node.configurationChoice} : {node.part}-{currentPrice}
          </Typography>
          {/* <Typography className={classes.title}>
          Price : {currentPrice}
        </Typography> */}
          {childNodes.length > 0 &&
            node.children.map((child) => {
              return configPrice(child);
            })}
        </Box>
      );
    };
    return (
      <div>
        <Modal
          disableEnforceFocus
          disableRestoreFocus
          disableAutoFocus
          onClose={() => this.props.closeModal()}
          className={classes.root}
          open={this.props.open}
        >
          <div>
            <div className={classes.closeIconContainer}>
              <IconButton
                color="secondary"
                className={classes.IconButton}
                onClick={() => this.props.closeModal()}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Card className={classes.card}>
              <CardHeader
                title=" Product Configuration"
                className={classes.cardHeader}
                action={
                  <Grid
                    container
                    alignItems="center"
                    className={classes.totalContainer}
                  >
                    <Grid item lg={4}>
                      <Typography variant="h6" color="primary">
                        Total Price : {totalPrice.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item lg={4}>
                      <Typography variant="h6" color="primary">
                        Tax : {(0.16 * totalPrice).toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item lg={4}>
                      <Typography variant="h6" color="primary">
                        Grand Total :
                        {(totalPrice + 0.16 * totalPrice).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
              <Divider />
              <CardContent>
                <div className={classes.btnContainer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.saveConfiguration()}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.saveCloseConfiguration()}
                  >
                    Save {`&`} Close
                  </Button>
                </div>
              </CardContent>
              <CardContent className={classes.contentContainer}>
                <Grid container spacing={2}>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <SortableTree
                      treeData={this.state.treeData}
                      canDrag={({ node }) => false}
                      canDrop={({ nextParent }) => false}
                      onChange={(treeData) => this.setState({ treeData })}
                      // nodeContentRenderer={TreeNodeRenderer}
                      generateNodeProps={({ node, path }) => ({
                        title: (
                          <Box className={classes.treeBox}>
                            <Typography className={classes.treeTitle}>
                              {node.configurationChoice + " :   "}
                            </Typography>

                            <select
                              name="parts"
                              className={classes.select}
                              label="Part Options"
                              disabled={node.groupFlag === "R"}
                              onChange={(event) => {
                                const partId = parseInt(event.target.value);
                                console.log("Selected Part : ", partId);
                                const selectedPart = this.state.compatibleParts.filter(
                                  (part) => part.id === partId
                                )[0];
                                const salePrice = selectedPart.salePrice.toFixed(
                                  2
                                );
                                const part = selectedPart.item;
                                if (node.groupFlag === "R") {
                                  const newBom = TempTreeData.filter(
                                    (element) =>
                                      element.partList[0].partId === partId
                                  );
                                  this.setState({
                                    treeData: newBom[0].partList,
                                  });
                                } else {
                                  this.setState({
                                    treeData: changeNodeAtPath({
                                      treeData: this.state.treeData,
                                      path,
                                      getNodeKey,
                                      newNode: {
                                        ...node,
                                        partId,
                                        salePrice,
                                        part,
                                      },
                                    }),
                                  });
                                }
                              }}
                            >
                              {node.options.map((element) => (
                                <option
                                  label={element.part}
                                  value={element.partId}
                                  selected={
                                    node.options.length === 1 ||
                                    node.partId === parseInt(element.partId)
                                  }
                                />
                              ))}
                            </select>
                          </Box>
                        ),
                      })}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={9} xs={9}>
                    {configPrice(this.state.treeData[0])}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProductConfiguration: (data) => {
      return dispatch(setProductConfiguration(data));
    },
  };
};

//export default ConfigurationModal;

const ConnectedConfigurationModalWindow = connect(
  null,
  mapDispatchToProps
)(ConfigurationModalWindow);

export default withStyles(styles, { withTheme: true })(
  ConnectedConfigurationModalWindow
);

// import React,{Component} from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import {
//   Modal,
//   Card,
//   CardContent,
//   CardActions,
//   Button,
//   IconButton,
//   Grid,
//   Box,
//   Typography,
//   CardHeader,
//   Divider,
//   List,
//   ListItem,
//   ListItemText,
//   withStyles,
// } from "@material-ui/core";
// import { useSelector, useDispatch } from "react-redux";
// import CloseIcon from "@material-ui/icons/CloseOutlined";
// import {
//   openConfigurationModal,
//   setProductConfiguration,
//   closeConfigurationModal,
// } from "../../redux/selector";
// import { connect } from 'react-redux'

// import SortableTree from 'react-sortable-tree';
// import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
// import partsData from '../cart/partsData';
// import TempTreeData from '../cart/TempTreeData';

// import { changeNodeAtPath,toggleExpandedForAll,getFlatDataFromTree } from '../cart/utils/tree-data-utils';

// const styles = (theme) => ({
//   root: {
//     height: "100vh",
//     width: "100vw",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   card: {
//     width: "95vw",
//   },
//   closeIconContainer: {
//     display: "flex",
//     justifyContent: "flex-end",
//   },
//   btnContainer: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   IconButton: {
//     color: "white",
//   },
//   contentContainer: {
//     maxHeight: "80vh",
//     overflow: "auto",
//   },
//   list: {
//     overflow: "auto",
//   },
//   CardContent: {
//     padding: "2px",
//   },
//   title: {
//     padding: theme.spacing(1),
//   },
// });

// // const useStyles = makeStyles((theme) => ({
// //   root: {
// //     height: "100vh",
// //     width: "100vw",
// //     display: "flex",
// //     justifyContent: "center",
// //     alignItems: "center",
// //   },
// //   card: {
// //     width: "95vw",
// //   },
// //   closeIconContainer: {
// //     display: "flex",
// //     justifyContent: "flex-end",
// //   },
// //   btnContainer: {
// //     display: "flex",
// //     justifyContent: "space-between",
// //     alignItems: "center",
// //   },
// //   IconButton: {
// //     color: "white",
// //   },
// //   contentContainer: {
// //     maxHeight: "80vh",
// //     overflow: "auto",
// //   },
// //   list: {
// //     overflow: "auto",
// //   },
// //   CardContent: {
// //     padding: "2px",
// //   },
// //   title: {
// //     padding: theme.spacing(1),
// //   },
// // }));

// class ConfigurationModalWindow extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       treeData: [{children:[],options:[],expanded: true}],
//       showImageSearch: false,
//       choice: '',
//       choiceOptions: [],
//       path: '',
//       compatibleParts:[]
//     };
//   }

//   //   static getDerivedStateFromProps(newProps, oldState) {
//   //   console.log('New PROPS : ',newProps);
//   //   if (newProps.configurationId) {
//   //     console.log('BEFORE FETCH configurationId : ',newProps.configurationId);
//   //     console.log('BEFORE FETCH typeof configurationId : ',typeof newProps.configurationId);

//   //     const newBom = TempTreeData.find(element => element.id === newProps.configurationId);
//   //     console.log('Specification : ',newBom);
//   //     return {
//   //       treeData: toggleExpandedForAll({ treeData:newBom.partList, expanded : true })
//   //     };
//   //   }

//   //   // Return null if the state hasn't changed
//   //   return null;
//   // }

//   async componentDidMount(){
//     const specId = this.props.configurationId;
//     if (specId) {
//       console.log('BEFORE FETCH configurationId : ',specId);
//       console.log('BEFORE FETCH typeof configurationId : ',typeof specId);
//       const token = localStorage.getItem("tkn");

//       const bomRequest =  await fetch(`http://172.105.38.78:9874/BOM/SalesBom/${specId}`, {
//         headers: {
//         Authorization: `Bearer ${token}`
//         }
//       })

//       let newBom = undefined;
//       //const newBom = TempTreeData.find(element => element.id === specId);
//       if(bomRequest.status === 200){
//         newBom = await bomRequest.json();
//       } else {
//         newBom = [];
//       }
//       console.log('Specification : ',newBom);

//       const partRequest =  await fetch(`http://172.105.38.78:9874/BOM/SalesBom/allparts`, {
//         headers: {
//         Authorization: `Bearer ${token}`
//         }
//       })

//       let parts = undefined;
//       //const parts = TempTreeData.find(element => element.id === specId);
//       if(partRequest.status === 200){
//         parts = await partRequest.json();
//       } else {
//         parts = [];
//       }
//       console.log('Specification : ',parts);

//       this.setState(state=>({ compatibleParts:parts,
//                               treeData: toggleExpandedForAll({ treeData:newBom.partList, expanded : true })}));

//     }
//   }

//   saveConfiguration = () => {
//     const data = {
//       // choices : getFlatDataFromTree({
//       //   treeData: this.state.treeData,
//       //   getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
//       //   ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
//       // }),
//       choices:this.state.treeData,
//       productId:this.props.productId,
//       selectedOptions:this.props.selectedOptions
//     };
//     this.props.setProductConfiguration(data);
//   }

//   saveCloseConfiguration = () => {
//     this.saveConfiguration();
//     this.props.closeConfigurationModal();

//   }

//   openConfigurationModal = () => {

//     this.props.openConfigurationModal();
//   }

//   closeConfigurationModal = () => {

//     this.props.closeConfigurationModal();

//   }

//   imageSearchOpen = (choice, options, path) => {

//     this.setState({
//       showImageSearch: true, choice: choice,
//       choiceOptions: options, path: path
//     });
//   }

//   imageSearchClose = () => {

//     this.setState({ showImageSearch: false });

//   }

//   render() {
//   const { classes } = this.props;

//   //const classes = useStyles();
//   const getNodeKey = ({ treeIndex }) => treeIndex;

//   let totalPrice = 0.00;

//   const configPrice = (node) => {
//     const childNodes = node.children || [];
//     const currentPrice = node.seperatePrice ? node.salePrice : 0.0;
//     totalPrice = totalPrice + parseFloat(currentPrice);
//     return (
//       <Box border="1px solid grey" m={1}>
//         <Typography className={classes.title}>
//           {node.configurationChoice} : {node.part} Price : {currentPrice}
//         </Typography>
//         {/* <Typography className={classes.title}>
//           Price : {currentPrice}
//         </Typography> */}
//         {childNodes.length > 0 &&
//           node.children.map((child) => {
//             return configPrice(child);
//           })}
//       </Box>
//     );
//   };
//   return (
//     <div>
//       <Modal
//         disableEnforceFocus
//         disableRestoreFocus
//         disableAutoFocus
//         onClose={() => this.props.closeModal()}
//         className={classes.root}
//         open={this.props.open}
//       >
//         <div>
//         <div className={classes.closeIconContainer}>
//             <IconButton
//               color="secondary"
//               className={classes.IconButton}
//               onClick={() => this.props.closeModal()}
//             >
//               <CloseIcon />
//             </IconButton>
//           </div>
//           <Card className={classes.card}>
//             <CardHeader title=" Product Configuration" />
//             <Divider />
//             <CardContent>
//               <div className={classes.btnContainer}>
//                 <Button
//                   variant="outlined"
//                   color="primary"
//                   onClick={() => this.saveConfiguration()}
//                 >
//                   Save
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="secondary"
//                   onClick={() => this.saveCloseConfiguration()}
//                 >
//                   Save {`&`} Close
//                 </Button>
//               </div>
//             </CardContent>
//             <CardContent className={classes.contentContainer}>
//               <Grid container spacing={2}>
//                 <Grid item lg={9} md={9} xs={12} sm={12}>
// <SortableTree
//     treeData={this.state.treeData}
//     canDrag={({ node }) => false}
//     canDrop={({ nextParent }) => false}
//     onChange={treeData => this.setState({ treeData })}
//     // nodeContentRenderer={TreeNodeRenderer}
//     generateNodeProps={({ node, path }) => ({
//       title: (<div><span>{node.configurationChoice + ' :   '}</span>
//         <select name="parts"
// label="Part Options"
// disabled={node.groupFlag === 'R'}
// onChange={event => {
//   const partId = parseInt(event.target.value);
//   console.log("Selected Part : ", partId);
//   const selectedPart = this.state.compatibleParts.filter(part => part.id === partId)[0];
//   const salePrice = selectedPart.salePrice.toFixed(2);
//   const part = selectedPart.item;
//   if (node.groupFlag === 'R') {
//     const newBom = TempTreeData.filter(element => element.partList[0].partId === partId);
//     this.setState({
//       treeData: newBom[0].partList
//     });
//   } else {

//     this.setState({
//       treeData: changeNodeAtPath({
//         treeData: this.state.treeData,
//         path,
//         getNodeKey,
//         newNode: { ...node, partId, salePrice, part },
//       }),
//     });

//   }
// }}
//         >
//           {node.options.map(element => <option label={element.part} value={element.partId} selected={node.options.length === 1 || node.partId === parseInt(element.partId)} />)}
//         </select>

//       </div>
//       ),
//     })}

//   />
//                 </Grid>

//                 <Grid item lg={3} md={3} sm={12} xs={12}>
//                   {configPrice(this.state.treeData[0])}
//                 </Grid>
//                 <Grid
//                   item
//                   lg={12}
//                   md={12}
//                   sm={12}
//                   xs={12}
//                   container
//                   direction="column"
//                   justify="flex-end"
//                 >
//                   <Grid item xs={12} sm={12} container justify="flex-end">
//                     <Typography variant="body1" color="primary">
//                       Total Price : {totalPrice.toFixed(2)}
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={12} sm={12} container justify="flex-end">
//                     <Typography variant="body1" color="primary">
//                       Tax : {(0.16 * totalPrice).toFixed(2)}
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={12} sm={12} container justify="flex-end">
//                     <Typography variant="body1" color="primary">
//                       Grand Total :{(totalPrice + 0.16 * totalPrice).toFixed(2)}
//                     </Typography>
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </CardContent>
//           </Card>
//         </div>
//       </Modal>
//     </div>
//   );
// };
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setProductConfiguration: (data) => {
//       return dispatch(setProductConfiguration(data))
//     }
//   }
// }

// //export default ConfigurationModal;

// const ConnectedConfigurationModalWindow = connect(null, mapDispatchToProps)(ConfigurationModalWindow);

// export default withStyles(styles, { withTheme: true })(ConnectedConfigurationModalWindow);
