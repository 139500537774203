import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Grid,
  Box,
  Typography,
  CardHeader,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  Input,
  Radio,
  TableFooter,
  Checkbox,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  TextField,
} from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useSelector, useDispatch, connect } from "react-redux";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import {
  setProductConfiguration,
  closeConfigurationModal,
  setProductPreferences,
  closePreferenceModal,
} from "../../redux/selector";
import * as LayoutInputs from "../Inputs/index";
import { ReactSvgPanZoomLoader } from "react-svg-pan-zoom-loader";
import { UncontrolledReactSVGPanZoom } from "react-svg-pan-zoom";
// import configuringProducts from "../../data/productData/configuringProducts.json";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    width: "95vw",
  },
  closeIconContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  IconButton: {
    color: "white",
  },
  contentContainer: {
    maxHeight: "80vh",
    overflow: "auto",
    backgroundColor: "red",
  },
  imgContainer: {
    height: "20vh",
    backgroundColor: "red",
    // width: "100%",
  },
  img: {
    backgroundColor: theme.palette.common.white,
    // objectFit: "contained",
    objectFit: "contain",
    // width: "80%",
    width: "20vw",
    height: "20vh",
  },
  TableContainer: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      overflow: "auto",
      height: "30vh",
    },
  },
  detailsContainer: {
    backgroundColor: "red",
    padding: 5,
    height: "10vh",
  },
  details: {
    fontSize: 15,
  },
}));

const classStyles = (theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    width: "95vw",
    minHeight: "80vh",
    overflow: "auto",
  },
  closeIconContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  IconButton: {
    color: "white",
  },
  contentContainer: {
    // maxHeight: "80vh",
    height: "50vh",
    // overflow: "auto",
  },
  imgContainer: {
    // height: "30vh",
    // backgroundColor: "red",
    // width: "100%",
  },
  img: {
    backgroundColor: theme.palette.common.white,
    // objectFit: "contained",
    objectFit: "contain",
    // width: "80%",
    width: "100%",
    // height: "20vh",
  },
  TableContainer: {
    height: "30vh",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      overflow: "auto",
      height: "30vh",
    },
  },
  detailsContainer: {
    backgroundColor: theme.palette.background.default,
    padding: 10,
    minHeight: "40vh",
  },
  details: {
    fontSize: 15,
  },
  /* and all your other styles ... */
});

const getInputs = (listName, ele, onChange) => {
  const Input = LayoutInputs[ele.dataType];
  return (
    <>
      <Input
        name={listName + "." + ele.masterProperty}
        label={undefined}
        disabled={!ele.selected}
        placeholder={"Enter Value"}
        value={ele.value}
        options={ele.options}
        onChange={onChange}
      />
    </>
  );
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const AllInputs = ({
  listName,
  list,
  listIndex,
  setComplete,
  setSelected,
  setInput,
}) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.TableContainer}>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell colSpan={1}>{"Select"}</StyledTableCell>
            {/* <StyledTableCell colSpan={1}>{"Name"}</StyledTableCell> */}
            <StyledTableCell colSpan={1}>{"Description"}</StyledTableCell>
            {/* <StyledTableCell colSpan={1}>{"Details"}</StyledTableCell> */}
            <StyledTableCell colSpan={1}>{"Guide"}</StyledTableCell>
            <StyledTableCell colSpan={1}>{"Min"}</StyledTableCell>
            <StyledTableCell colSpan={1}>{"Max"}</StyledTableCell>
            <StyledTableCell colSpan={1}>{"Input"}</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {list.map((ele, index) => (
            <StyledTableRow>
              <StyledTableCell>
                <Radio
                  name={listName}
                  value={ele.id}
                  checked={ele.selected}
                  onChange={(e, { value }) => {
                    allVerifyId = value;
                    ele.selected = ele.id === allVerifyId;
                    setSelected(
                      listIndex,
                      index,
                      ele.imageFilePath + ele.imageFileName
                    );
                  }}
                />
              </StyledTableCell>

              {/* <StyledTableCell>{ele.masterProperty}</StyledTableCell> */}

              <StyledTableCell>{ele.description}</StyledTableCell>
              {/* <StyledTableCell>{ele.details}</StyledTableCell> */}
              <StyledTableCell>{ele.guide}</StyledTableCell>
              <StyledTableCell>{ele.min}</StyledTableCell>
              <StyledTableCell>{ele.max}</StyledTableCell>

              <StyledTableCell>
                {getInputs(listName, ele, (e) =>
                  setInput(listIndex, index, e.target.value)
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <StyledTableCell colSpan={8}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  list.length > 0 && list.filter((ele) => !ele.value).length > 0
                }
                onClick={() => {
                  // console.log("Completed List :", list);
                  setComplete(listIndex);
                }}
              >
                COMPLETE
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

let singleInputId = undefined;
const SingleInput = ({
  listName,
  list,
  listIndex,
  setComplete,
  setSelected,
  setInput,
}) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.TableContainer}>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell colSpan={1}>{"Select"}</StyledTableCell>
            {/* <StyledTableCell colSpan={1}>{"Name"}</StyledTableCell> */}
            <StyledTableCell colSpan={1}>{"Description"}</StyledTableCell>
            {/* <StyledTableCell colSpan={1}>{"Details"}</StyledTableCell> */}
            <StyledTableCell colSpan={1}>{"Guide"}</StyledTableCell>
            <StyledTableCell colSpan={1}>{"Min"}</StyledTableCell>
            <StyledTableCell colSpan={1}>{"Max"}</StyledTableCell>
            <StyledTableCell colSpan={1}>{"Input"}</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {list.map((ele, index) => (
            <StyledTableRow>
              <StyledTableCell>
                <Radio
                  name={listName}
                  value={ele.id}
                  checked={ele.selected}
                  onChange={(e, { value }) => {
                    setSelected(
                      listIndex,
                      index,
                      ele.imageFilePath + ele.imageFileName
                    );
                  }}
                />
              </StyledTableCell>

              {/* <StyledTableCell>{ele.masterProperty}</StyledTableCell> */}

              <StyledTableCell>{ele.description}</StyledTableCell>
              {/* <StyledTableCell>{ele.details}</StyledTableCell> */}
              <StyledTableCell>{ele.guide}</StyledTableCell>
              <StyledTableCell>{ele.min}</StyledTableCell>
              <StyledTableCell>{ele.max}</StyledTableCell>

              <StyledTableCell>
                {getInputs(listName, ele, (e) =>
                  setInput(listIndex, index, e.target.value)
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <StyledTableCell colSpan={8}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  list.length > 0 &&
                  !(list.filter((ele) => ele.value).length > 0)
                }
                onClick={() => {
                  console.log("Completed List :", list);
                  setComplete(listIndex);
                }}
              >
                COMPLETE
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

let allVerifyId = undefined;
const AllVerify = ({
  listName,
  list,
  listIndex,
  setComplete,
  setSelected,
  setCheckbox,
}) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.TableContainer}>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell colSpan={1}>{"Select"}</StyledTableCell>
            {/* <StyledTableCell colSpan={1}>{"Name"}</StyledTableCell> */}
            <StyledTableCell colSpan={1}>{"Description"}</StyledTableCell>
            {/* <StyledTableCell colSpan={1}>{"Details"}</StyledTableCell> */}
            <StyledTableCell colSpan={1}>{"Guide"}</StyledTableCell>
            <StyledTableCell colSpan={1}>{"Verify"}</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {list.map((ele, index) => (
            <StyledTableRow>
              <StyledTableCell>
                <Radio
                  name={listName}
                  value={ele.id}
                  checked={ele.selected}
                  onChange={(e, { value }) => {
                    allVerifyId = value;
                    ele.selected = ele.id === allVerifyId;
                    setSelected(
                      listIndex,
                      index,
                      ele.imageFilePath + ele.imageFileName
                    );
                  }}
                />
              </StyledTableCell>

              {/* <StyledTableCell>{ele.masterProperty}</StyledTableCell> */}

              <StyledTableCell>{ele.description}</StyledTableCell>
              {/* <StyledTableCell>{ele.details}</StyledTableCell> */}
              <StyledTableCell>{ele.guide}</StyledTableCell>
              <StyledTableCell>
                {getInputs(listName, ele, (e) =>
                  setCheckbox(listIndex, index, e.target.checked)
                )}
                {/* <Checkbox
                  label={ele.value || "Nil"}
                  name={ele.masterProperty}
                  disabled={!ele.selected}
                  //value={checkboxState[ele.master_property]}
                  checked={ele.verified}
                  onChange={(e) =>
                    setCheckbox(listIndex, index, e.target.checked)
                  }
                /> */}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <StyledTableCell colSpan={6}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  list.length > 0 &&
                  list.filter((ele) => !ele.verified).length > 0
                }
                onClick={() => {
                  console.log("Completed List :", list);
                  setComplete(listIndex);
                }}
              >
                COMPLETE
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const DisplayText = ({
  list,
  listIndex,
  setComplete,
  setActiveStep,
  setConfirmClose,
}) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.TableContainer}>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell colSpan={5}>
              <p>Confirmation</p>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell colSpan="5">
              <p>{list[0]}</p>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <StyledTableCell colSpan={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  //first element in the specification list
                  // setActiveStep(0);
                  setComplete(listIndex);
                  // setComplete();
                }}
              >
                REVIEW
              </Button>
            </StyledTableCell>
            <StyledTableCell colSpan={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setComplete(listIndex);
                  setConfirmClose();
                }} // onClick={() => {
                //   setComplete(listIndex);
                //   setConfirmClose();
                // }}
              >
                CLOSE
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

class PreferenceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewURL: "",
      previewDetails: undefined,
      activeStep: 0,
      preferences: {
        id: props.id,
        productId: props.productId,
        selectedOptions: {},
        specificationList: [{ name: "", selectionType: null }],
      },
      isComplete: [],
    };

    this.typeContent = this.typeContent.bind(this);
  }

  // static getDerivedStateFromProps(newProps, oldState) {
  //   if (oldState.preferences.id != newProps.specificationId && newProps.specificationId !== null) {
  //     let newPreferences=configuringProducts.find( configElement => configElement.id === newProps.specificationId );
  //     newPreferences.productId=newProps.productId;
  //     newPreferences.selectedOptions=newProps.selectedOptions;
  //     let newCompleteStatus = newPreferences.specificationList.map(ele=>false);
  //     newCompleteStatus.push(false); //isConfirm
  //     return {
  //       preferences: newPreferences,isComplete:newCompleteStatus
  //     };
  //   }

  //   // Return null if the state hasn't changed
  //   return null;
  // }

  async componentDidMount() {
    const specId = this.props.specificationId;
    if (specId) {
      console.log("BEFORE FETCH configurationId : ", specId);
      console.log("BEFORE FETCH typeof configurationId : ", typeof specId);
      const token = localStorage.getItem("tkn");

      const bomRequest = await fetch(
        `http://172.235.17.44:9874/ECOM/TechPack/${specId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let newTechPack = undefined;
      //const newTechPack = TempTreeData.find(element => element.id === specId);
      if (bomRequest.status === 200) {
        newTechPack = await bomRequest.json();
      } else {
        newTechPack = [];
      }
      console.log("Specification : ", newTechPack);

      newTechPack.productId = this.props.productId;
      newTechPack.selectedOptions = this.props.selectedOptions;
      newTechPack.id = this.props.id;
      let newCompleteStatus = newTechPack.specificationList.map((ele) => false);
      newCompleteStatus.push(false); //isConfirm

      this.setState((state) => ({
        preferences: newTechPack,
        isComplete: newCompleteStatus,
      }));
    }
  }

  handleChange = (value) => {
    this.setState({ previewURL: value });
  };

  closeConfigurationModal = () => {
    this.props.setProductPreferences(this.state.preferences);
    this.props.closeModal();
    // this.props.closePreferenceModal();
  };

  setComplete = (listIndex) => {
    let newCompleteStatus = this.state.isComplete;
    let nextActiveStep = this.state.activeStep;
    newCompleteStatus[listIndex] = true;
    if (
      !newCompleteStatus[newCompleteStatus.length - 1] &&
      listIndex < newCompleteStatus.length - 1
    ) {
      nextActiveStep = listIndex + 1;
    } else if (listIndex === newCompleteStatus.length - 1) {
      nextActiveStep = 0;
    }

    // if (
    //   listIndex < newCompleteStatus.length - 1 &&
    //   !newCompleteStatus[listIndex + 1]
    // ) {
    //   nextActiveStep = listIndex + 1;
    // }
    this.setState({
      isComplete: newCompleteStatus,
      previewURL: "",
      previewDetails: "null",
      activeStep: nextActiveStep,
    });
    console.log("thisstate", this.state);
  };

  setNextStep = () => {
    const listlength = this.state.preferences.specificationList.length + 1; // last thank you step included
    this.setState({ activeStep: (this.state.activeStep + 1) % listlength });
  };

  setPreviousStep = () => {
    const listlength = this.state.preferences.specificationList.length;

    this.setState({ activeStep: (this.state.activeStep - 1) % listlength });
  };
  setSelected = (listIndex, index, imageUrl) => {
    let newState = this.state;
    newState.preferences.specificationList[
      listIndex
    ].generalAttributeList.forEach((ele, ind) => {
      if (ind !== index) {
        newState.preferences.specificationList[listIndex].generalAttributeList[
          ind
        ].selected = false;
      }
    });
    newState.preferences.specificationList[listIndex].generalAttributeList[
      index
    ].selected = true;
    newState.previewURL = imageUrl;
    newState.previewDetails =
      newState.preferences.specificationList[listIndex].generalAttributeList[
        index
      ].details || "null";
    this.setState(newState);
  };

  setCheckbox = (listIndex, index, value) => {
    let newStatePreferences = this.state.preferences;
    newStatePreferences.specificationList[listIndex].generalAttributeList[
      index
    ].verified = value;
    this.setState({ preferences: newStatePreferences });
  };

  setInput = (listIndex, index, value) => {
    let newStatePreferences = this.state.preferences;
    newStatePreferences.specificationList[listIndex].generalAttributeList[
      index
    ].value = value;
    this.setState({ preferences: newStatePreferences });
  };

  setActiveStep = (value) => {
    this.setState({ activeStep: value });
    // let newComplete = this.state.isComplete;
    // if (newComplete[newComplete.length - 1]) {
    //   this.setState({ activeStep: value });
    // }
  };
  typeContent(listName, typeName, listIndex) {
    if (typeName === "VerifyAll") {
      return (
        <AllVerify
          listName={listName}
          listIndex={listIndex}
          list={
            this.state.preferences.specificationList[listIndex]
              .generalAttributeList
          }
          setComplete={this.setComplete}
          setSelected={this.setSelected}
          setCheckbox={this.setCheckbox}
          handleChange={this.handleChange}
        />
      );
    } else if (typeName === "SingleInput") {
      return (
        <SingleInput
          listName={listName}
          listIndex={listIndex}
          list={
            this.state.preferences.specificationList[listIndex]
              .generalAttributeList
          }
          setComplete={this.setComplete}
          setSelected={this.setSelected}
          setInput={this.setInput}
          handleChange={this.handleChange}
        />
      );
    } else if (typeName === "AllInput") {
      return (
        <AllInputs
          listName={listName}
          listIndex={listIndex}
          list={
            this.state.preferences.specificationList[listIndex]
              .generalAttributeList
          }
          setComplete={this.setComplete}
          setSelected={this.setSelected}
          setInput={this.setInput}
          handleChange={this.handleChange}
        />
      );
    } else if (typeName === "display") {
      return (
        <DisplayText
          listName={listName}
          list={["Thank you for providing Product inputs"]}
          listIndex={listIndex}
          setComplete={this.setComplete}
          setActiveStep={this.setActiveStep}
          setConfirmClose={this.closeConfigurationModal}
        />
      );
    }
  }

  render() {
    const { classes } = this.props;
    console.log("style classes", classes);

    const { typeContent, setActiveStep, closeConfigurationModal } = this;

    const { activeStep, preferences, isComplete } = this.state;

    let inputMappping = preferences.specificationList.map((element, index) => (
      <Step key={index} completed={isComplete[index]}>
        <StepLabel>{element.name}</StepLabel>
      </Step>
    ));

    const inlineStyle = {
      modal: {
        marginTop: "0px !important",
        marginLeft: "auto",
        marginRight: "auto",
      },
    };

    const focusContent = () => {
      if (activeStep < isComplete.length - 1) {
        // if (
        //   !isComplete[activeStep] ||
        //   (isComplete[activeStep] && isComplete[isComplete.length - 1])
        // ) {
        //   return typeContent(
        //     preferences.specificationList[activeStep].name,
        //     preferences.specificationList[activeStep].selectionType,
        //     activeStep
        //   );
        // }
        // console.log("current active step", activeStep);
        // console.log("current state", this.state);

        return typeContent(
          preferences.specificationList[activeStep].name,
          preferences.specificationList[activeStep].selectionType,
          activeStep
        );
      } else {
        return typeContent("thankyou", "display", activeStep);
      }
    };

    return (
      <div>
        <Modal
          disableEnforceFocus
          disableRestoreFocus
          disableAutoFocus
          onClose={() => this.props.closeModal()}
          className={classes.root}
          open={this.props.open}
        >
          <div>
            <Card className={classes.card}>
              <CardHeader
                title=" Product Preference"
                action={
                  <IconButton
                    color="secondary"
                    className={classes.IconButton}
                    onClick={() => this.props.closeModal()}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent>
                <Grid container justify="space-between">
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={
                        !this.state.isComplete[this.state.activeStep - 1] ||
                        this.state.activeStep === 0
                      }
                      onClick={this.setPreviousStep}
                    >
                      Previous
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.setNextStep}
                      disabled={
                        !this.state.isComplete[this.state.activeStep + 1] ||
                        this.state.activeStep === this.state.isComplete.length
                      }
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Stepper activeStep={activeStep} orientation="horizontal">
                  {inputMappping}
                </Stepper>
              </CardContent>
              <CardContent className={classes.contentContainer}>
                <Grid container spacing={1}>
                  <Grid item lg={9} md={9} sm={12} xs={12}>
                    {focusContent()}
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <div className={classes.imgContainer}>
                      <TransformWrapper>
                        <TransformComponent>
                          <img
                            className={classes.img}
                            src={this.state.previewURL}
                          />
                        </TransformComponent>
                      </TransformWrapper>

                      {/* <img
                        className={classes.img}
                        src={this.state.previewURL}
                      /> */}
                    </div>
                    {this.state.previewDetails && (
                      <div className={classes.detailsContainer}>
                        <Typography className={classes.details}>
                          {this.state.previewDetails}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { openPreference: state.shop.openPreference };
};
const mapDispatchToProps = (dispatch) => {
  return {
    closePreferenceModal: () => {
      return dispatch(closePreferenceModal());
    },
    setProductPreferences: (data) => {
      return dispatch(setProductPreferences(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(classStyles)(PreferenceModal));
// export default withStyles(classStyles)(PreferenceModal);